

export {  data} 

let data = [

    //     "id": 123, # 아이디는 어떤수자건 상관없음, type 과 date 로 분류됨

    {
        "id": 3000370,
        "title": "Parasyte: The Grey",
        "channel":"Netflix",
        "detail": "A group of mysterious parasitic creatures fall from outer space",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/TGR_thumbnail.jpg",
        "date": new Date('2024-04-1'),
        "vimeo":"https://player.vimeo.com/video/941511969",
    },
    {
        "id": 3000360,
        "title": "Queen of Tears",
        "channel":"TVN",
        "detail": "3년차 부부의 아찔한 위기와 기적처럼 다시 시작되는 사랑 이야기",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/QOT_thumbnail.jpg",
        "date": new Date('2024-03-1'),
        "vimeo":"https://player.vimeo.com/video/941489769",
    },
    {
        "id": 3000350,
        "title": "웨딩임파서블",
        "channel":"TVN",
        "detail": "An unknown actress, Na A-jung, tired of her monotonous minor roles, desires to take center stage in life.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/WED_thumbnail.jpg",
        "date": new Date('2024-02-1'),
        "vimeo":"https://player.vimeo.com/video/940387365",
    },
    {
        "id": 3000340,
        "title": "경성크리쳐",
        "channel":"Netflix",
        "detail": "All suspicions led to a hospital in Gyeongseong where there were humans and creatures",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/KSC_thumbnail.jpg",
        "date": new Date('2023-12-1'),
        "vimeo":"https://player.vimeo.com/video/940405792",
    },
        {
        "id": 3000330,
        "title": "My Dearest",
        "channel":"MBC",
        "detail": "몹시 그리워하고 사랑한 연인, 장현 X 길채의 마지막 이야기",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/LUV_thumbnail.jpg",
        "date": new Date('2023-08-4'),
        "vimeo":"https://player.vimeo.com/video/891704836",
    },
        {
        "id": 2000160,
        "title": "LOVE RESET",
        "channel":"한국 ",
        "detail": "D-30 of their long-awaited divorce when disaster strikes, wiping away both of their memories of each other.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/DAY_thumbnail.jpg",
        "date": new Date('2023-10-1'),
        "vimeo":"https://player.vimeo.com/video/939505637",
    },
    {
        "id": 3000320,
        "title": "킹더랜드",
        "channel":"JTBC",
        "detail": "킹더랜드",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/KTL_thumbnail.jpg",
        "date": new Date('2023-06-17'),
        "vimeo":"https://player.vimeo.com/video/940360246",
    },
    {
        "id": 3000310,
        "title": "Celebrity",
        "channel":"Netflix",
        "detail": "Fame. Money. Power. See A-ri achieves social media stardom overnight. But deadly consequences await in this glitzy, gilded world of influencers.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/CBR_thumbnail.jpg",
        "date": new Date('2023-06-1'),
        "vimeo":"https://player.vimeo.com/video/845510926",
    },
    {
        "id": 2000150,
        "title": "귀공자",
        "channel":"한국 ",
        "detail": "'난 단 한번도 타겟을 놓쳐 본 적이 없거든' 필리핀에서 불법 경기장을 전전하며 병든 어머니와 살아가는 복싱 선수 '마르코'.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/SAD_thumbnail.jpg",
        "date": new Date('2023-06-1'),
        "vimeo":"https://player.vimeo.com/video/845497805",
    },
    {
        "id": 2000140,
        "title": "The Roundup: No Way Out ",
        "channel":"한국 ",
        "detail": "Ma Seok-do joins a new squad to investigate a murder case.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/OL3_thumbnail.jpg",
        "date": new Date('2023-05-1'),
        "vimeo":"https://player.vimeo.com/video/845689367",
    },
    {
        "id": 3000300,
        "title": "The Lies Hidden in My Garden",
        "channel":"Genee/Hulu/Amazon/Netflix",
        "detail": "In an upscale new house, a woman’s life with her husband and son takes a dark turn",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/MDN_thumbnail.jpg",
        "date": new Date('2023-06-19'),
        "vimeo":"https://player.vimeo.com/video/845672293",
    },
    {
        "id": 3000290,
        "title": "더 글로리",
        "channel":"Netflix",
        "detail": "Year after surviving horrific abuse in high school, a woman puts an elaborate revenge scheme in motion to make the perpetrators pay for their crimes",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/GLR_thumbnail.jpg",
        "date": new Date('2022-12-30'),
        "vimeo":"https://player.vimeo.com/video/845499692",
    },
    {
        "id": 3000280,
        "title": "Crash Coure in Romance",
        "channel":"TVN/Netflix",
        "detail": "A mother with a heart of gold navigates the cutthroat world of private education when her daughter tries to join a celebrity math instructor’s class.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/SCN_thumbnail.jpg",
        "date": new Date('2022-12-2'),
        "vimeo":"https://player.vimeo.com/video/846571782",
    },
    {
        "id": 3000270,
        "title": "퀸메이커",
        "channel":"Netflix",
        "detail": "After a tragic accident, a powerful fixer uses her skills to transform a civil right lawyer into the next mayor.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/QMK_thumbnail.jpg",
        "date": new Date('2022-12-1'),
        "vimeo":"https://player.vimeo.com/video/850521070",
    },
    {
        "id": 2000130,
        "title": "Come Back Home ",
        "channel":"한국",
        "detail": "개그맨이 되겠다는 꿈 하나로 서울 상경을 결심한 ‘기세’ 그러나 갑작스러운 프로그램 폐지 소식과 함께 꿈도, 돈도, 집도 하루아침에 모든 것을 잃게 되고 15년 만에 고향을 찾는다.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/CB_thumbnail.jpg",
        "date": new Date('2022-10-1'),
        "vimeo":"https://player.vimeo.com/video/775264830",
    },
    {
        "id": 3000260,
        "title": "Chain Reaction",
        "channel":"Coupang Play",
        "detail": "드디어 시작된 짜릿한 데이트 여정 체인 리액션.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/CRA_thumbnail.jpg",
        "date": new Date('2022-09-1'),
        "vimeo":"https://player.vimeo.com/video/775265423",
    },
    {
        "id": 3000250,
        "title": "May It Please the Court",
        "channel":"Disney+/Hulu",
        "detail": "성공을 위해 무엇이든 물어뜯는 독종 변호사 ‘노착희’",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/DF_thumbnail.jpeg",
        "date": new Date('2022-09-1'),
        "vimeo":"https://player.vimeo.com/video/775265455"
    },
    {
        "id": 3000240,
        "title": "Love in Contract",
        "channel":"TVN/Tving",
        "detail": "나의 맨얼굴을 사랑하는 것보다, 맨 마음을 사랑하는 게 더 힘들다.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/MON_thumbnail.jpg",
        "date": new Date('2022-09-1'),
        "vimeo":"https://player.vimeo.com/video/775265494"
    },
    {
        "id": 3000230,
        "title": "From Now On, Showtime!",
        "channel":"MBC",
        "detail": "카리스마 마술사와 신통력을 지닌 열혈 순경의 귀신 공조 코믹 수사극",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/STM_thumbnail.jpg",
        "date": new Date('2022-06-1'),
        "vimeo":"https://player.vimeo.com/video/775265405?h=8d39ad7596&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000120,
        "title": "578 Magnum ",
        "channel":"베트남",
        "detail": "A father who is ready to hunt the whole criminal underworld down for his daughter",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/T5_thumbnail.jpg",
        "date": new Date('2022-05-1'),
        "vimeo":"https://player.vimeo.com/video/775264786?h=9ed72521b3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000220,
        "title": "Tracer ",
        "channel":"MBC/Wavve",
        "detail": "누군가에게는 판검사보다 무서운곳 국세청. 조세 5국에 굴러온 독한놈의 활약을 그린 통쾌한 추적 활극.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/TC_thumbnail.jpg",
        "date": new Date('2022-03-1'),
        "vimeo":"https://player.vimeo.com/video/775265388?h=1225d28964&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000210,
        "title": "스물다섯 스물하나 ",
        "channel":"TVN/Netflix",
        "detail": "A teen fencer pursues big ambition and meet a hardworking young man who seeks to rebuild his life.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/TF_thumbnail.jpg",
        "date": new Date('2022-02-1'),
        "vimeo":"https://player.vimeo.com/video/775265327?h=1838f8fe8e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000200,
        "title": "All of us are dead ",
        "channel":"Netflix",
        "detail": "A high school becomes ground zero for a zombie virus outbreak.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/SC_thumbnail.jpg",
        "date": new Date('2022-01-1'),
        "vimeo":"https://player.vimeo.com/video/775265310?h=3ab08b7b22&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000190,
        "title": "The Silent Sea ",
        "channel":"Netflix",
        "detail": "Space explorers try to retrieve samples from an abandoned research facility steeped in classified secrets.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/SS_thumbnail.jpg",
        "date": new Date('2021-12-1'),
        "vimeo":"https://player.vimeo.com/video/775265290?h=6a6a6f3185&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000180,
        "title": "Bulgasal ",
        "channel":"TVN/Netflix",
        "detail": "Cursed since birth and exempt from death, a revenge-driven immortal sets out on a quest to reclaim his soul and end a 600 year old vendetta.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/BG_thumbnail.jpg",
        "date": new Date('2021-12-1'),
        "vimeo":"https://player.vimeo.com/video/775265242?h=274c32c742&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000170,
        "title": "Raindrop ",
        "channel":"Disney+",
        "detail": "A blood-soaked man bursts into the dormitory of a women’s university in Seoul, Korea",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/EW_thumbnail.jpeg",
        "date": new Date('2021-12-1'),
        "vimeo":"https://player.vimeo.com/video/775265259?h=5783a9cffd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000160,
        "title": "아머드사우르스 ",
        "channel":"대원/SBS",
        "detail": "근 미래, DNA 복제 기술로 고대 생명체인 공룡을 부활시킨 인류, 외계세력의 침공을 맞아 공룡에게 전투 메카닉을 결합시켜 대항한다.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/AS_thumbnail.jpg",
        "date": new Date('2021-11-1'),
        "vimeo":"https://player.vimeo.com/video/775265180?h=b820bcf7bd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000150,
        "title": "One Ordinary Day",
        "channel":"Coupang Play",
        "detail": "평범한 대학생에서 하룻밤 사이 살인 용의자가 된 현수.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/OD_thumbnail.jpg",
        "date": new Date('2021-11-1'),
        'vimeo':"https://player.vimeo.com/video/775265208?h=2dcfb1d052&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000110,
        "title": "The Labyrinth ",
        "channel":"한국",
        "detail": "어둠이 깃든 땅을 학생들의 맑은 기운으로 봉인한 연두고등학교",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/WD_thumbnail.jpeg",
        "date": new Date('2021-10-1'),
        "vimeo":"https://player.vimeo.com/video/775264760?h=dd4a8438dd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000140,
        "title": "구경이 ",
        "channel":"JTBC/Netflix",
        "detail": "A reclusive ex-cop reenters the game as an insurance investigator.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/KU_thumbnail.jpg",
        "date": new Date('2021-10-1'),
        "vimeo":"https://player.vimeo.com/video/775265148?h=e7f9ac4ea0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000100,
        "title": "The Battle at Lake Changjin ",
        "channel":"중국",
        "detail": "In the freezing cold Changjin Lake region, a bloody battle between the United States and China begins.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/XX_thumbnail.jpg",
        "date": new Date('2021-09-1'),
        "vimeo":"https://player.vimeo.com/video/775264746?h=747322ae12&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000130,
        "title": "One the Woman	",
        "channel":"SBS/Netflix",
        "detail": "A corrupt prosecutor loses her memory.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/OW_thumbnail.jpg",
        "date": new Date('2021-09-1'),
        "vimeo":"https://player.vimeo.com/video/775265123?h=2c3a3f4ab5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000120,
        "title": "Devil Judge ",
        "channel":"TVN/Netflix",
        "detail": "In a near future dystopia, an enigmatic judge punishes the haves by caring out vision of justice.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/AK_thumbnail.jpg",
        "date": new Date('2021-07-1'),
        "vimeo":"https://player.vimeo.com/video/775265082?h=9360717cc9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000090,
        "title": "Recalled ",
        "channel":"한국",
        "detail": "깨어난 후, 다른 사람의 미래가 보이기 시작했다",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/TM_thumbnail.jpeg",
        "date": new Date('2021-04-1'),
        "vimeo":"https://player.vimeo.com/video/775264726?h=d4e9b6eac6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000110,
        "title": "Taxi Driver ",
        "channel":"SBS/Netflix",
        "detail": "A former special soldier delivers revenge for victims of injustice",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/MT_thumbnail.jpg",
        "date": new Date('2021-04-1'),
        "vimeo":"https://player.vimeo.com/video/775265047?h=740b0cb935&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000100,
        "title": "Joseon Exorcist ",
        "channel":"SBS",
        "detail": "부활한 악령과 생시들로부터 나라를 지키기 위한 산자들의 전쟁을 그려내는 조선 엑소시즘 판타지 사극!",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/JK_thumbnail.jpg",
        "date": new Date('2021-03-1'),
        "vimeo":"https://player.vimeo.com/video/775265024?h=a849c665c5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
 
    {
        "id": 2000080,
        "title": "Best Friend ",
        "channel":"한국",
        "detail": "적인가, 이웃인가? 낮에는 친근한 이웃집 vs 밤에는 수상한 도청팀백수가장 좌천위기 도청팀장 대권",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/NB_thumbnail.jpeg",
        "date": new Date('2020-11-1'),
        "vimeo":"https://player.vimeo.com/video/775264699?h=323bb0fb4b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000090,
        "title": "The Uncanny Counter ",
        "channel":"OCN/Netflix",
        "detail": "The Uncanny Counter, use special abilities to chase down malevolent spirits that prey on humans.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/RU_thumbnail.jpeg",
        "date": new Date('2020-11-1'),
        "vimeo":"https://player.vimeo.com/video/775265008?h=9101d0a55b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000080,
        "title": "Flower of Evil ",
        "channel":"TVN/Netflix",
        "detail": "Hiding a twisted past, a man maintains his facade as the perfect husband to his detective wife.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/FL_thumbnail.jpg",
        "date": new Date('2020-07-1'),
        "vimeo":"https://player.vimeo.com/video/775264995?h=0e6078bafa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000070,
        "title": "Mulan ",
        "channel":"미국",
        "detail": "Hua Mulan, the eldest daughter of an honored warrior, steps in to take the place of her ailing father.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/MU_thumbnail.jpg",
        "date": new Date('2020-03-1'),
        "vimeo":"https://player.vimeo.com/video/775264678?h=127756899b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000070,
        "title": "Rugal	",
        "channel":"OCN/Netflix",
        "detail": "A police detective who loses everything to a criminal organization seeks payback.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/RG_thumbnail.jpg",
        "date": new Date('2020-03-1'),
        "vimeo":"https://player.vimeo.com/video/775264972?h=6c1c90bbcf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
  
    {
        "id": 4000010,
        "title": "EXO 콘서트",
        "channel":"",
        "detail": "EXO Dome Concert",
        "type": "comercial",
        "poster":"https://i.vimeocdn.com/video/1555482469-b7d6867ab23a99ebae6ad66a0f86956cf916881c98bd3f5042b883b66e807ab9-d_295x166",
        "date": new Date('2018-12-1'),
        "vimeo":"https://player.vimeo.com/video/775265541?h=6859ef6d41"
    },
    // {
    //     "id": 34,
    //     "title": "매직랜드",
    //     "channel":"",
    //     "detail": "매직랜드",
    //     "type": "comercial",
    //     "poster":"https://vumbnail.com/775265574.jpg",
    //     "date": new Date('2018-12-1'),
    //     "vimeo":"https://player.vimeo.com/video/775265574?h=2e5aebb4a1&badge=0&autopause=0&player_id=0&app_id=58479/embed"
    // },
    {
        "id": 3000060,
        "title": "Ever Night ",
        "channel":"중국",
        "detail": "In the ancient world lies a prophecy: Upon the arrival of Yong Ye, the world will be thrown into Chaos.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/JY_thumbnail.jpg",
        "date": new Date('2018-10-1'),
        "vimeo":"https://player.vimeo.com/video/775264937?h=5fa23fd40a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000050,
        "title": "사생결단로맨스",
        "channel":"MBC",
        "detail": "내분비내과 의사와 분노와 짜증 외 다른 감정이라고는 찾아볼 수 없는 천재 신경외과 의사가 얽히며 벌어지는 호르몬밀당 로맨스.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/RO_thumbnail.jpg",
        "date": new Date('2018-07-1'),
        "vimeo":"https://player.vimeo.com/video/775264929?h=9473bcd86f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000060,
        "title": "여중생 A",
        "channel":"한국",
        "detail": "게임 세계 원더링 월드에서 자신만의 세상을 꿈꾸며 살아가는 여중생 미래. 난생 처음 현실 친구를 사귀려 용기를 내지만..",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/GA_thumbnail.jpg",
        "date": new Date('2018-06-1'),
        "vimeo":"https://player.vimeo.com/video/775264650?h=1e97c4d3ee&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000040,
        "title": "诗王争霸		",
        "channel":"중국",
        "detail": "传说中有一个罕见的天才，他苦心钻研数十年，终于悟出了世间除“金木水火土”五行之外的另一种元素-暗影元素。",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/SI_thumbnail.jpg",
        "date": new Date('2018-03-1'),
        "vimeo":"https://player.vimeo.com/video/775264911?h=a8a2830cd3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000030,
        "title": "조선농민사전	",
        "channel":"농림부",
        "detail": "조선시대 고서를 발견 후 미래 식량의 대안으로 곤충에 대한 다큐멘터리를 완성해가는 이야기.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/CH_thumbnail.jpg",
        "date": new Date('2018-01-1'),
        "vimeo":"https://player.vimeo.com/video/775264896?h=6bbd8bf7ba&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 2000050,
        "title": "Resurrected Victims",
        "channel":"한국",
        "detail": "A prosecutor falls under suspicion for the murder of his mother when she comes back from the dead.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/BH_thumbnail.jpeg",
        "date": new Date('2017-10-1'),
        "vimeo":"https://player.vimeo.com/video/775264598?h=e83009e1f2&badge=0&autopause=0&player_id=0&app_id=58479/embed"
    },
    {
        "id": 2000040,
        "title": "점박이 한반도의 공룡2	",
        "channel":"한국",
        "detail": "백악기를 지배한 공룡의 제왕 ‘점박이’의 새로운 모험이 시작된다!",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/JP_thumbnail.jpg",
        "date": new Date('2017-10-1'),
        "vimeo":"https://player.vimeo.com/video/775264634?h=2b0e6b8d71&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    {
        "id": 3000020,
        "title": "The K2 ",
        "channel":"TVN/Netflix",
        "detail": "A fugitive soldier gets swept up in personal and political intrigue.",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/K2_thumbnail.jpg",
        "date": new Date('2016-09-1'),
        "vimeo":"https://player.vimeo.com/video/775264875?h=7f53dcd59a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    }, 
    {
        "id": 2000030,
        "title": "Operation Chromite ",
        "channel":"한국",
        "detail": "A team of South Korean spies goes behind enemy lines.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/OC_thumbnail.jpg",
        "date": new Date('2016-07-1'),
        "vimeo":"https://player.vimeo.com/video/775264555?h=7ed632b21e&badge=0&autopause=0&player_id=0&app_id=58479/embed"
    },
    {
        "id": 2000020,
        "title": "대역전 Tik Tok ",
        "channel":"한국/중국",
        "detail": "스포츠 도박으로 전 재산을 날리고 스트레스 장애 및 조울증으로 치료를 받고 있는 곽지다.  그는 중국인 정신과 의사인 양시와 상담 중 한국 경찰들의 습격을 받는다.",
        "type": "feature",
        "poster":"https://skyfall.studio/thumbnails/TI_thumbnail.jpg",
        "date": new Date('2016-07-1'),
        "vimeo":"https://player.vimeo.com/video/775264593?h=dc2da41b76&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    
    {
        "id": 2000010,
        "title": "평안도",
        "channel":"한국/중국",
        "detail": "해양 탐사팀이 이름 모를 무인도에 불시착한다.",
        "type": "feature",
        "poster":"https://i.vimeocdn.com/video/1561457396-ea1dfc13fdcaf8a305c63e05c9302779296f1fce199a295259a93c92fb831553-d_295x166",
        "date": new Date('2015-12-2'),
        "vimeo":"https://player.vimeo.com/video/775264543?h=19904067d9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },   
    {
        "id": 3000010,
        "title": "푸른바다의전설",
        "channel":" SBS/Netflix",
        "detail": "A mermaid from the Joseon period ends up in present-day Seoul",
        "type": "series",
        "poster":"https://skyfall.studio/thumbnails/BS_thumbnail.jpg",
        "date": new Date('2016-11-1'),
        "vimeo":"https://player.vimeo.com/video/775264888?h=3bd98a97e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
    },
    // {
    //     "id": 46,
    //     "title": "엘지인버터",
    //     "channel":"",
    //     "detail": "LG inverter Promotion",
    //     "type": "comercial",
    //     "poster":"https://vumbnail.com/775265531.jpg",
    //     "date": new Date('2015-12-1'),
    //     "vimeo":"https://player.vimeo.com/video/775265531?h=32116073e4"
    // },
 
    {
        "id": 1000010,
        "title": "3D and FX",
        "detail": "Our 3D and VFX services take content and media to the next level, through sought-after design services to create stunning visuals.",
        "type": "work",
        "poster":"https://skyfall.studio/thumbnails/skyfall_thumbnail.jpg",
        "date": new Date('2021-06-21 19:55'),
        "vimeo":"https://player.vimeo.com/video/775264236",
        
    },
    {
        "id": 1000020,
        "title": "Comp and Mattepaint",
        "detail": "Our 3D and VFX services take content and media to the next level, through sought-after design services to create stunning visuals.",
        "type": "work",
        "poster":"https://skyfall.studio/thumbnails/skyfall_thumbnail.jpg",
        "date": new Date('2021-06-21 19:00'),
        "vimeo":"https://player.vimeo.com/video/775264321",
    },
    {
        "id": 1000030,
        "title": "Cosmetic Fixes",
        "detail": "Digital beauty treatments and cosmetic fixes becoming main stream in post-production, isolating skin with tracking and mattes for color correction and matching, skin smoothing, and blemish removal.",
        "type": "work",
        "poster":"https://skyfall.studio/thumbnails/skyfall_thumbnail.jpg",
        "date": new Date('2021-06-21 19:01'),
        "vimeo":"https://player.vimeo.com/video/775264401",

    },
    {
        "id": 1000040,
        "title": "Paint-Remove and Cleanup",
        "detail": "Delete the unwanted or extend the horizons of your live footage effortlessly.",
        "type": "work",
        "poster":"https://skyfall.studio/thumbnails/skyfall_thumbnail.jpg",
        "date": new Date('2021-06-21 19:00'),
        "vimeo":"https://player.vimeo.com/video/775264413",
    },
    {
        "id": 1000050,
        "title": "Rotoscoping and Keying",
        "detail": "Our team has delivered unmatched rotoscope solutions for over 1.3 million frames for over a decade.",
        "type": "work",
        "poster":"https://skyfall.studio/thumbnails/skyfall_thumbnail.jpg",
        "date": new Date('2021-06-27 14:45'),
        "vimeo": "https://player.vimeo.com/video/775264493",
    },
    {
        "id": 1000060,
        "title": "Screen-x",
        "detail": "ScreenX is a panoramic film format which presents films with an expanded, dual-sided, 270-degree screens projected on the walls in a theater.",
        "type": "work",
        "poster":"https://skyfall.studio/thumbnails/skyfall_thumbnail.jpg",
        "date": new Date('2021-06-21 19:01'),
        "vimeo":"https://player.vimeo.com/video/775264531",
    },
]