<template>
  <div class="home">
    <!-- start banner -->
    <banner :name="this.name" :bannerImg="'banner_home1.png'"></banner>
    <!-- end banner -->

    <div class="recent_shows wrapper-video">
      <section class="recent_shows_carousel">
        <div class="container">
          <h2>Recent Shows</h2>
          <div class="carousel flex-gap">
            <article
              style="position: relative"
              v-for="el in this.data
                .filter((e) =>
                  ['feature', 'series', 'comercial'].includes(e.type)
                )
                .sort((a, b) => b.date - a.date)
                .slice(0, 9)"
              :key="el.id"
              @mouseout="mouseout"
            >
              <div
                class="img"
                style="
                  position: relative;
                  height: 100%;
                  display: grid;
                  align-content: center;
                  overflow: hidden;
                "
              >
                <div
                  class="video_wrapper"
                  style="
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    display: grid;
                    align-items: center;
                  "
                >
                  <div style="padding: 56.25% 0 0 0; position: relative">
                    <div
                      class="btn-play"
                      @click="playVimeo"
                      @mouseover="mouseOver"
                      @mouseout="mouseout"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        @mouseout="mouseout"
                      >
                        <path
                          @mouseout="mouseout"
                          fill="white"
                          d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                        />
                      </svg>
                    </div>
                    <iframe
                      :src="el.vimeo"
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                      style="
                        position: absolute;
                        border-radius: 12px;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                      "
                      class="vimeo"
                      loading="lazy"
                    ></iframe>
                    <div
                      class="thumbnail"
                      style="
                        position: absolute;
                        border-radius: 12px;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                      "
                    >
                      <img :src="el.poster" loading="lazy" />
                    </div>
                    <div
                      class="fullscreenn"
                      @click="outFullScreen"
                      style="
                        color: white;
                        z-index: 1000001;
                        position: fixed;
                        top: 5%;
                        right: 2%;
                        display: none;
                      "
                    >
                      <i class="fa-solid fa-x" style="color: white"></i>
                    </div>
                  </div>
                </div>
              </div>
              <p class="title">{{ el.title }}</p>
              <p class="detail">
                {{ el.detail }}
              </p>
              <p class="channel">{{ el.channel }}</p>
            </article>
            <article class="seemore">
              <router-link to="gallery">See more</router-link>
            </article>
          </div>
        </div>
      </section>
      <div class="btn_controls">
        <div class="left" id="carousel_left1" @click="left">
          <svg viewBox="0 0 13 29" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#000"
              d="M12.716 28.349l-.779.651L0 14.5 11.937 0l.779.651L1.303 14.5z"
            />
          </svg>
        </div>
        <div class="right" @click="right" id="carousel_right1">
          <svg viewBox="0 0 13 29" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#000"
              d="M12.716 28.349l-.779.651L0 14.5 11.937 0l.779.651L1.303 14.5z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="line"></div>

    <!--start works -->
    <div class="works wrapper-video">
      <section class="works_carousel">
        <div class="container">
          <h2>Works</h2>
          <div class="carousel flex-gap">
            <article
              style="position: relative"
              v-for="el in this.data.filter((e) => e.type == 'work')"
              @mouseout="mouseout"
              :key="el.id"
            >
              <div
                class="img"
                style="
                  position: relative;
                  height: 100%;
                  display: grid;
                  align-content: center;
                  overflow: hidden;
                "
              >
                <div
                  class="video_wrapper"
                  style="
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    display: grid;
                    align-items: center;
                  "
                >
                  <div style="padding: 56.25% 0 0 0; position: relative">
                    <div
                      class="btn-play"
                      @click="playVimeo"
                      @mouseover="mouseOver"
                      @mouseout="mouseout"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        @mouseout="mouseout"
                      >
                        <path
                          @mouseout="mouseout"
                          fill="white"
                          d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                        />
                      </svg>
                    </div>
                    <iframe
                      :src="el.vimeo"
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                      style="
                        position: absolute;
                        border-radius: 12px;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                      "
                      class="vimeo"
                      loading="lazy"
                    ></iframe>
                    <div
                      class="thumbnail"
                      style="
                        position: absolute;
                        border-radius: 12px;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                      "
                    >
                      <img :src="el.poster" loading="lazy" />
                    </div>

                    <div
                      class="fullscreenn"
                      @click="outFullScreen"
                      style="
                        color: white;
                        z-index: 1000001;
                        position: fixed;
                        top: 5%;
                        right: 2%;
                        display: none;
                      "
                    >
                      <i class="fa-solid fa-x" style="color: white"></i>
                    </div>
                  </div>
                </div>
              </div>
              <p class="title">{{ el.title }}</p>
              <p class="detail">
                {{ el.detail }}
              </p>
            </article>
          </div>
        </div>
      </section>
      <div class="btn_controls">
        <div class="left" id="carousel_left2" @click="left">
          <svg viewBox="0 0 13 29" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#000"
              d="M12.716 28.349l-.779.651L0 14.5 11.937 0l.779.651L1.303 14.5z"
            />
          </svg>
        </div>
        <div class="right" @click="right" id="carousel_right2">
          <svg viewBox="0 0 13 29" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#000"
              d="M12.716 28.349l-.779.651L0 14.5 11.937 0l.779.651L1.303 14.5z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- end works -->

    <!--start more-about -->
    <more-about></more-about>
    <!--end more-about -->

    <!--start Footer -->
    <Footer></Footer>
    <!--end Footer -->
  </div>
</template>

<style scoped lang="scss">
.vimeo {
  visibility: collapse;
}
article:hover {
  .btn-play {
    opacity: 1;
  }
}
.btn-play {
  height: 30px;
  width: 60px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  display: grid;
  place-items: center;
  border-radius: 5px;
  z-index: 3;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  svg {
    height: 16px;
    width: 16px;
    z-index: 4;
  }
}
.btn-play:hover {
  background: rgb(0, 173, 239);
}
.unset {
  transform: unset !important;
}
.home.black {
  background: #000;
  color: black;
}
.overlayyy {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1); /* Black background with opacity */
  z-index: 14; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.img img {
  // position: absolute;
  width: 100%;
  border-radius: 12px;
}

.home {
  .wrapper-video {
    position: relative;
    margin: 50px auto;
    section {
      .container {
        max-width: 1320px;
        margin: 0 auto;
        overflow: hidden;
        h2 {
          margin-bottom: 10px;
        }
        // .carousel{

        // }
        .flex-gap {
          transition: 2s transform ease;
          --grid-rows: 1;
          --grid-column-gap: 4px;
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: calc((100% - 4 * var(--grid-column-gap)) / 5);
          grid-template-rows: repeat(var(--grid-rows), -webkit-max-content);
          grid-column-gap: var(--grid-column-gap);
          overscroll-behavior-x: none;
          scroll-snap-type: x mandatory;
          article {
            display: grid;
            grid-template-rows: 120px 36px 50px 20px;
            margin: 6px;
            overflow: hidden;

            .img {
              position: relative;
              cursor: pointer;
              display: grid;
              align-items: center;
              justify-items: center;
              &:hover {
              }
            }
            .title {
              display: grid;
              align-content: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14.5px;
              font-weight: 400;
              line-height: 1.47;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
            .detail {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              font-size: 12px;
              font-weight: 400;
              line-height: 1.34;
              color: rgba(0, 0, 0, 0.56);
              cursor: pointer;
              font-family: "Apple Korea";
            }
            .channel {
              font-size: 12px;
              display: grid;
              justify-content: end;
              cursor: pointer;
            }
          }
          .seemore {
            display: grid;
            align-items: center;
            justify-items: center;
            cursor: pointer;
            font-weight: 700;
            a {
              color: #2c3e50;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .btn_controls {
      height: 100%;
      .left {
        transform: scaleX(-1);
        right: -5px;

        svg {
          position: absolute;
          top: 45%;
          path {
          }
        }
      }
      .right {
        left: -5px;
        svg {
          position: absolute;
          top: 45%;
          path {
          }
        }
      }
      .left,
      .right {
        opacity: 0;
        cursor: pointer;
        height: 100%;
        margin: 10px;
        position: absolute;
        top: 0;
        width: 15px;
      }
      .left:hover,
      .right:hover {
        transition: 250ms opacity linear;
        opacity: 1;
      }
    }
  }
  .line {
    height: 2px;
    background: #f2f2f2;
    max-width: 1300px;
    margin: 50px auto;
  }
}

.recent_shows {
  .carousel:hover .left,
  .carousel:hover .right {
    transition: 250ms opacity linear;
    opacity: 1;
  }
}

.works {
  .carousel:hover .left,
  .carousel:hover .right {
    transition: 250ms opacity linear;
    opacity: 1;
  }
}

@media only screen and (min-width: 1400px) {
  .wrapper-video {
    max-width: 1370px;
    .container {
      max-width: 1320px;
    }
  }

  .line {
    max-width: 1320px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .wrapper-video {
    max-width: 1190px !important;
    .container {
      max-width: 1140px !important;
    }
  }

  .line {
    max-width: 1140px !important;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wrapper-video {
    max-width: 1010px !important;
    .container {
      max-width: 960px !important;
    }
  }

  .line {
    max-width: 960px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wrapper-video {
    max-width: 770px !important;

    .container {
      max-width: 720px !important;
    }
    .flex-gap {
      --grid-column-gap: 4px !important;
      grid-auto-columns: calc(
        (100% - 3 * var(--grid-column-gap)) / 4
      ) !important;
    }
  }
  .line {
    max-width: 700px;
  }

  .wrapper-video .right,
  .wrapper-video .left {
    width: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper-video {
    padding: 5px !important;
    margin: 10px auto !important;
    .flex-gap {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      overflow-x: scroll !important;
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important;
      grid-auto-columns: 95% !important;
      grid-template-rows: repeat(
        var(--grid-rows),
        -webkit-max-content
      ) !important;
      grid-column-gap: 10px !important;
    }
  }
  .line {
    margin: 10px auto;
  }
  .btn_controls {
    display: none;
  }
}
@media only screen and (min-width: 1400px) {
  article {
    grid-template-rows: 147px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  article {
    grid-template-rows: 127px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  article {
    grid-template-rows: 112px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  article {
    grid-template-rows: 102px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 737px) and (max-width: 767px) {
  article {
    grid-template-rows: 432px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 661px) and (max-width: 736px) {
  article {
    grid-template-rows: 388px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 631px) and (max-width: 660px) {
  article {
    grid-template-rows: 348px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 630px) {
  article {
    grid-template-rows: 332px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 551px) and (max-width: 600px) {
  article {
    grid-template-rows: 316px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 550px) {
  article {
    grid-template-rows: 289px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 461px) and (max-width: 500px) {
  article {
    grid-template-rows: 262px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 415px) and (max-width: 460px) {
  article {
    grid-template-rows: 241px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 410px) and (max-width: 414px) {
  article {
    grid-template-rows: 216px 39px 50px 20px !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 409px) {
  article {
    grid-template-rows: 213px 39px 50px 20px !important;
  }
}
@media only screen and (max-width: 359px) {
  article {
    grid-template-rows: 187px 37px 40px 20px !important;
  }
}
.fullscreen {
  position: fixed !important;
  inset: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1000000 !important;
}

.collapse {
  visibility: collapse;
}
</style>
<script src="https://player.vimeo.com/api/player.js"></script>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner.vue";
import MoreAbout from "@/components/MoreAbout.vue";
import Footer from "@/components/Footer.vue";
import { data } from "@/assets/js/data_list.js";
import $ from "jquery";
export default {
  name: "HomeView",
  components: {
    Banner,
    Footer,
    MoreAbout,
  },
  data() {
    return {
      span: document.getElementsByTagName("span"),
      displayIconLeft: 1,
      displayIconRight: 0,
      carousel: document.querySelectorAll(".carousel"),
      data: data,
      idLeft1: "carousel_left1",
      idLeft2: "carousel_left2",
      idRight1: "carousel_right1",
      idRight2: "carousel_right2",
      abc: ["Banner1_after.mov", "Banner1_before.mov"],
      videoID: "775513637",
      height: 500,
      options: {
        loop: false,
      },
      playerReady: false,
      name: this.$options.name,
      bannerImg:'banner_home1.png'
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    outFullScreen(el) {
      if (el.target.tagName == "svg") {
        el.target.parentElement.style.display = "none";
        let iframes =
          el.target.parentElement.parentElement.querySelectorAll("iframe");
        iframes[0].style.display = "none";
        let thumbnail =
          el.target.parentElement.parentElement.querySelector(".thumbnail");
        thumbnail.style.display = "block";
        iframes[0].parentElement.querySelector(".btn-play").style.display =
          "grid";
        iframes[0].parentElement
          .querySelector(".btn-play")
          .querySelector("path")
          .setAttribute("fill", "white");
      }
      if (el.target.tagName == "path") {
        el.target.parentElement.parentElement.style.display = "none";

        let iframes =
          el.target.parentElement.parentElement.parentElement.querySelectorAll(
            "iframe"
          );
        let thumbnail1 =
          el.target.parentElement.parentElement.parentElement.querySelector(
            ".thumbnail"
          );
        iframes[0].style.display = "none";
        thumbnail1.style.display = "block";

        iframes[0].parentElement.querySelector(".btn-play").style.display =
          "grid";
        iframes[0].parentElement
          .querySelector(".btn-play")
          .querySelector("path")
          .setAttribute("fill", "white");
      }
      if (el.target.tagName == "DIV") {
        el.target.style.display = "none";
        let iframes = el.target.parentElement.querySelectorAll("iframe");
        iframes[0].style.display = "none";
        let thumbnail2 = el.target.parentElement.querySelector(".thumbnail");
        thumbnail2.style.display = "block";

        iframes[0].parentElement.querySelector(".btn-play").style.display =
          "grid";
        iframes[0].parentElement
          .querySelector(".btn-play")
          .querySelector("path")
          .setAttribute("fill", "white");
      }
      let btns_fullscrenn = document.querySelectorAll(".fullscreenn");
      for (let i = 0; i < btns_fullscrenn.length; i++) {
        btns_fullscrenn[i].style.display = "none";
      }

      const iframes = document.querySelectorAll(".img iframe");
      if (document?.exitFullscreen) {
        document?.exitFullscreen();
      } else if (document?.webkitExitFullscreen) {
        document?.webkitExitFullscreen();
      } else if (document?.mozCancelFullScreen) {
        document?.mozCancelFullScreen();
      } else if (document?.msExitFullscreen) {
        document?.msExitFullscreen();
      }
      let playe = [];
      for (let i = 0; i < iframes.length; i++) {
        playe[i] = new Vimeo.Player(iframes[i]);
        playe[i].pause();
        iframes[i].classList.remove("fullscreen");
        document.documentElement.style.overflow = "auto";
        document.querySelector(".carousel").classList.remove("unset");
        document.querySelector(".banner").classList.remove("overlayyy");
        document
          .querySelector(".banner")
          .querySelector(".logo_mobile")
          .classList.remove("aaa");
        document
          .querySelector(".banner")
          .querySelector(".banner_video")
          .classList.remove("aaa");
        document.querySelector(".line").classList.remove("collapse");
        document.querySelector("footer").classList.remove("black");
        document
          .querySelector("footer")
          .querySelector(".container")
          .classList.remove("collapse");
        document.querySelector(".more_about").classList.remove("black");
        document
          .querySelector(".more_about")
          .querySelector(".more_about_laptop")
          .classList.remove("collapse");
        document.querySelector("#app").classList.remove("black");
        let lines = document.querySelectorAll(".line");
        for (let k = 0; k < lines.length; k++) {
          lines[k].classList.remove("collapse");
        }
        for (let j = 0; j < iframes.length; j++) {
          if (i != j) {
            iframes[j].classList.remove("collapse");
            iframes[j].parentElement
              .querySelector(".btn-play")
              .classList.remove("collapse");
          }
        }
      }

      let thumb = document.querySelectorAll(".thumbnail");
      for (let t = 0; t < thumb.length; t++) {
        thumb[t].style.display = "block";
        thumb[t].style.visibility = "unset";
      }
    },
    mouseOver(el) {
      // console.log(el.target.tagName);
      if (el.target.tagName == "path") {
        el.target?.setAttribute("fill", "black");
      }
      if (el.target.tagName == "svg") {
        el.target.querySelector("path")?.setAttribute("fill", "black");
      }
      if (el.target.tagName == "DIV") {
        el.target.querySelector("path")?.setAttribute("fill", "black");
      }
    },
    mouseout(el) {
      el.target.querySelector("path")?.setAttribute("fill", "white");
    },

    playVimeo(el) {
      const iframes = document.querySelectorAll(".img iframe");
      for (let i = 0; i < iframes.length; i++) {
        iframes[i].style.visibility = "collapse";
      }
      if (el.target.tagName == "svg") {
        let iframes = el.target.parentElement.parentElement.querySelectorAll(".img iframe");
        let btn_play = el.target.parentElement.parentElement.querySelector(".btn-play");
        function vimeoPlay(){
          let player = new Vimeo.Player(iframes[0]);
          player.play().then(function(){
            btn_play.style.display = "none";
            iframes[0].style.visibility = "unset";
            el.target.parentElement.style.display = "none";
            el.target.parentElement.parentElement.querySelector(
              ".fullscreenn"
            ).style.display = "block";
            let thumbnail3 = document.querySelectorAll(".thumbnail");
            for (let i = 0; i < thumbnail3.length; i++) {
              if (thumbnail3[i]) {
                thumbnail3[i].style.display = "none";
              }
            }
          })
          .catch(function(error) {
              switch (error.name) {
                  case 'PasswordError':
                      console.log('PasswordError1')
                       el.target.parentElement.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
                  case 'PrivacyError':
                      console.log('PrivacyError1')
                      el.target.parentElement.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);

                      break;
                  default:
                      console.log('error1')
                      el.target.parentElement.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
              }
          });
      }
      vimeoPlay()
        // btn_play.style.display = "none";
        // player.play();
        // iframes[0].style.visibility = "unset";
        // el.target.parentElement.style.display = "none";
        // el.target.parentElement.parentElement.querySelector(
        //   ".fullscreenn"
        // ).style.display = "block";
        // let thumbnail3 = document.querySelectorAll(".thumbnail");
        // for (let i = 0; i < thumbnail3.length; i++) {
        //   if (thumbnail3[i]) {
        //     thumbnail3[i].style.display = "none";
        //   }
        // }
      } else if (el.target.tagName == "path") {
        const iframes1 = el.target.parentElement.parentElement.parentElement.querySelectorAll( ".img iframe");
        let btn_play = el.target.parentElement.parentElement.parentElement.querySelector(".btn-play");
        function vimeoPlay(){
          let player1 = new Vimeo.Player(iframes1[0]);
          player1.play().then(function(){
            btn_play.style.display = "none";
            iframes1[0].style.visibility = "unset";
            let thumbnail3 = document.querySelectorAll(".thumbnail");
            for (let i = 0; i < thumbnail3.length; i++) {
              if (thumbnail3[i]) {
                thumbnail3[i].style.display = "none";
              }
            }
            el.target.parentElement.parentElement.style.display = "none";
            el.target.parentElement.parentElement.parentElement.querySelector(".fullscreenn").style.display = "block";
          })
          .catch(function(error) {
              switch (error.name) {
                  case 'PasswordError':
                      console.log('PasswordError2')
                      el.target.parentElement.parentElement.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.parentElement.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.parentElement.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.parentElement.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
                  case 'PrivacyError':
                      console.log('PrivacyError2')
                      el.target.parentElement.parentElement.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.parentElement.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.parentElement.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.parentElement.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
                  default:
                      console.log('error2')
                      el.target.parentElement.parentElement.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.parentElement.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.parentElement.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.parentElement.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
              }
          });
      }
      vimeoPlay()
        // btn_play.style.display = "none";
        // player1.play();
        // iframes1[0].style.visibility = "unset";
        // let thumbnail3 = document.querySelectorAll(".thumbnail");
        // for (let i = 0; i < thumbnail3.length; i++) {
        //   if (thumbnail3[i]) {
        //     thumbnail3[i].style.display = "none";
        //   }
        // }
        // el.target.parentElement.parentElement.style.display = "none";
        // el.target.parentElement.parentElement.parentElement.querySelector(".fullscreenn").style.display = "block";
      } else if (el.target.tagName == "DIV") {
        const iframes2 = el.target.parentElement.querySelectorAll(".img iframe");
        let thumbnail3 = document.querySelectorAll(".thumbnail");
        function vimeoPlay(){
          let player2 = new Vimeo.Player(iframes2[0]);
          player2.play().then(function(){
            iframes2[0].style.visibility = "unset";
            for (let i = 0; i < thumbnail3.length; i++) {
              if (thumbnail3[i]) {
                thumbnail3[i].style.display = "none";
              }
            }
            let btn_play = el.target.parentElement.parentElement.querySelector(".btn-play");
            btn_play.style.display = "none";
            el.target.style.display = "none";
            el.target.parentElement.querySelector(".fullscreenn").style.display ="block";
          })
          .catch(function(error) {
              switch (error.name) {
                  case 'PasswordError':
                      console.log('PasswordError3')
                      el.target.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
                  case 'PrivacyError':
                      console.log('PrivacyError3')
                      el.target.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
                  default:
                      console.log('error3')
                      el.target.parentElement.querySelector('.thumbnail').style.display = "none"
                      el.target.parentElement.querySelector('iframe').style.visibility = "unset"
                      setTimeout(() => {
                        el.target.parentElement.querySelector('.thumbnail').style.display = "block"
                        el.target.parentElement.querySelector('iframe').style.visibility = "collapse"
                      }, 3000);
                      break;
              }
          });
      }
      vimeoPlay();
        // iframes2[0].style.visibility = "unset";
        // for (let i = 0; i < thumbnail3.length; i++) {
        //   if (thumbnail3[i]) {
        //     thumbnail3[i].style.display = "none";
        //   }
        // }
        // let btn_play = el.target.parentElement.parentElement.querySelector(".btn-play");
        // btn_play.style.display = "none";
        // el.target.style.display = "none";
        // el.target.parentElement.querySelector(".fullscreenn").style.display ="block";
      }
    },
    fullscreen() {
      let iframes = document.querySelectorAll(".img iframe");

      let playe = [];
      for (let i = 0; i < iframes.length; i++) {
        playe[i] = new Vimeo.Player(iframes[i], this.options);
        playe[i].on("ended", function () {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
          iframes[i].style.display = "none";
          let thumb = document.querySelectorAll(".thumbnail");
          for (let t = 0; t < thumb.length; t++) {
            thumb[t].style.display = "block";
            thumb[t].style.visibility = "unset";
          }

          let btns_fullscrenn = document.querySelectorAll(".fullscreenn");
          for (let i = 0; i < btns_fullscrenn.length; i++) {
            btns_fullscrenn[i].style.display = "none";
          }
          iframes[i].parentElement.querySelector(".btn-play").style.display =
            "grid";
          iframes[i].parentElement
            .querySelector(".btn-play")
            .querySelector("path")
            .setAttribute("fill", "white");
          document.documentElement.style.overflow = "auto";
          let carousel = document.querySelectorAll(".carousel");
          for (let m = 0; m < carousel.length; m++) {
            carousel[m].classList.remove("unset");
          }
          document.querySelector(".banner").classList.remove("overlayyy");
          document
            .querySelector(".banner")
            .querySelector(".logo_mobile")
            .classList.remove("aaa");
          document
            .querySelector(".banner")
            .querySelector(".banner_video")
            .classList.remove("aaa");
          // document.querySelector(".home").classList.remove("black");
          document.querySelector(".line").classList.remove("collapse");
          document.querySelector("footer").classList.remove("black");
          document
            .querySelector("footer")
            .querySelector(".container")
            .classList.remove("collapse");
          document.querySelector(".more_about").classList.remove("black");
          document
            .querySelector(".more_about")
            .querySelector(".more_about_laptop")
            .classList.remove("collapse");
          document.querySelector("#app").classList.remove("black");
          let lines = document.querySelectorAll(".line");
          for (let k = 0; k < lines.length; k++) {
            lines[k].classList.remove("collapse");
          }
          for (let j = 0; j < iframes.length; j++) {
            if (i != j) {
              iframes[j].classList.remove("collapse");
              iframes[j].parentElement
                .querySelector(".btn-play")
                .classList.remove("collapse");
            }
          }
        });

        playe[i].on("play", function () {
          let btns_fullscrenn = document.querySelectorAll(".fullscreenn");
          btns_fullscrenn[i].style.display = "block";
          document.documentElement.style.overflow = "hidden";
          let thumb = document.querySelectorAll(".thumbnail");
          for (let t = 0; t < thumb.length; t++) {
            thumb[t].style.display = "none";
          }
          var elem = document.body;
          requestFullScreen(elem);
          function requestFullScreen(element) {
            var requestMethod =
              element.requestFullScreen ||
              element.webkitRequestFullScreen ||
              element.mozRequestFullScreen ||
              element.msRequestFullscreen;
            if (requestMethod) {
              requestMethod.call(element);
            }
          }
          iframes[i].classList.add("fullscreen");
          iframes[i].style.display = "block";
          let carousel = document.querySelectorAll(".carousel");
          for (let m = 0; m < carousel.length; m++) {
            carousel[m].classList.add("unset");
          }
          document.querySelector(".banner").classList.add("overlayyy");
          document
            .querySelector(".banner")
            .querySelector(".logo_mobile")
            .classList.add("aaa");
          document
            .querySelector(".banner")
            .querySelector(".banner_video")
            .classList.add("aaa");
          // document.querySelector(".home").classList.add("black");
          document.querySelector("footer").classList.add("black");
          document
            .querySelector("footer")
            .querySelector(".container")
            .classList.add("collapse");
          document.querySelector(".more_about").classList.add("black");
          document
            .querySelector(".more_about")
            .querySelector(".more_about_laptop")
            .classList.add("collapse");
          let lines = document.querySelectorAll(".line");
          for (let k = 0; k < lines.length; k++) {
            lines[k].classList.add("collapse");
          }

          document.querySelector("#app").classList.add("black");
          for (let j = 0; j < iframes.length; j++) {
            if (i != j) {
              iframes[j].classList.add("collapse");
              iframes[j].parentElement
                .querySelector(".btn-play")
                .classList.add("collapse");
            }
          }
        });
      }
      $(document).bind(
        "fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange",
        function (e) {
          var fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
          let playe = [];
          let iframes = document.querySelectorAll(".img iframe");

          if (!fullscreenElement) {
            for (let i = 0; i < iframes.length; i++) {
              playe[i] = new Vimeo.Player(iframes[i]);
              playe[i].pause();
              iframes[i].classList.remove("fullscreen");

              for (let j = 0; j < iframes.length; j++) {
                if (i != j) {
                  iframes[j].classList.remove("collapse");
                  iframes[j].parentElement
                    .querySelector(".btn-play")
                    .classList.remove("collapse");
                }
              }
            }
            if (document?.exitFullscreen) {
              document?.exitFullscreen();
            } else if (document?.webkitExitFullscreen) {
              document?.webkitExitFullscreen();
            } else if (document?.mozCancelFullScreen) {
              document?.mozCancelFullScreen();
            } else if (document?.msExitFullscreen) {
              document?.msExitFullscreen();
            }
            document.documentElement.style.overflow = "auto";
            let carousel = document.querySelectorAll(".carousel");
            for (let m = 0; m < carousel.length; m++) {
              carousel[m].classList.remove("unset");
            }
            document.querySelector(".banner").classList.remove("overlayyy");
            document
              .querySelector(".banner")
              .querySelector(".logo_mobile")
              .classList.remove("aaa");
            document
              .querySelector(".banner")
              .querySelector(".banner_video")
              ?.classList?.remove("aaa");
            // document.querySelector(".home").classList.remove("black");
            document.querySelector(".line").classList.remove("collapse");
            document.querySelector("footer").classList.remove("black");
            document
              .querySelector("footer")
              .querySelector(".container")
              .classList.remove("collapse");
            document?.querySelector(".more_about")?.classList.remove("black");
            document
              ?.querySelector(".more_about")
              ?.querySelector(".more_about_laptop")
              ?.classList.remove("collapse");
            document.querySelector("#app").classList.remove("black");
            let lines = document.querySelectorAll(".line");
            for (let k = 0; k < lines.length; k++) {
              lines[k].classList.remove("collapse");
            }
            for (let i = 0; i < iframes.length; i++) {
              iframes[i].classList.remove("fullscreen");
            }
            let btn_x = document.querySelectorAll(".fullscreenn");
            for (let a = 0; a < btn_x.length; a++) {
              btn_x[a].style.display = "none";
            }
            let btn_play = document.querySelectorAll(".btn-play");
            for (let b = 0; b < btn_play.length; b++) {
              iframes[b].parentElement.querySelector(
                ".btn-play"
              ).style.display = "grid";
              iframes[b].parentElement
                .querySelector(".btn-play")
                .querySelector("path")
                .setAttribute("fill", "white");
            }
            let vimeo = document.querySelectorAll(".vimeo");
            for (let t = 0; t < vimeo.length; t++) {
              if (vimeo[t].style.visibility == "unset") {
                vimeo[t].style.visibility = "collapse";
              }
            }
            let thumb = document.querySelectorAll(".thumbnail");
            for (let t = 0; t < thumb.length; t++) {
              if (thumb[t].style.visibility == "collapse") {
                thumb[t].style.visibility = "unset";
                thumb[t].style.display = "block";
              }
              thumb[t].style.display = "block";
              thumb[t].style.visibility = "unset";
            }
            thumb[0].scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }
      );
    },
    myEventHandler(e) {
      let mq1 = window.matchMedia("(max-width: 768px)");
      if (mq1.matches) {
        let a = document.querySelectorAll(".carousel");
        a[0].style.transform = "unset";
        a[1].style.transform = "unset";
      }
    },

    right(el) {
      let id;
      if (el.target.tagName == "svg") {
        id = el.target.parentElement.id;
      } else if (el.target.tagName == "path") {
        id = el.target.parentElement.parentElement.id;
      }
      let items = document
        .querySelector(`#${id}`)
        .parentElement.parentElement.querySelectorAll(".carousel article");
      let carousel;
      if (id == "carousel_right2") {
        carousel = this.carousel[1];
      }
      if (id == "carousel_right1") {
        carousel = this.carousel[0];
      }
      let leftItems;
      let styleTransform = carousel.style.transform;
      let percentColumnWidth =
        getComputedStyle(carousel).gridAutoColumns.match(/\d+/g)[0];
      leftItems = (styleTransform.match(/\d+/g)[0] * 1) / percentColumnWidth;
      let itemsInRow = 100 / percentColumnWidth;
      let styleGridAutoColumn = getComputedStyle(
        document.querySelector(".flex-gap")
      ).gridAutoColumns.match(/\d+/g);
      if (leftItems <= itemsInRow) {
        carousel.style.transform = `translateX(calc(-${
          styleTransform.match(/\d+/g)[0] * 1 - leftItems * percentColumnWidth
        }% - ${
          styleTransform.match(/\d+/g)[1] * 1 -
          (styleGridAutoColumn[1] / (itemsInRow - 1)) * leftItems
        }px`;
      } else {
        carousel.style.transform = `translateX(calc(-${
          styleTransform.match(/\d+/g)[0] * 1 - itemsInRow * percentColumnWidth
        }% - ${
          styleTransform.match(/\d+/g)[1] * 1 -
          (styleGridAutoColumn[1] / (itemsInRow - 1)) * itemsInRow
        }px`;
      }

      if (carousel.style.transform.match(/\d+/g)[0] == 0) {
        this.displayIconRight = 0;
      } else {
        this.displayIconRight = 1;
      }
      if (
        carousel.style.transform.match(/\d+/g)[0] <
        (items.length - itemsInRow) * percentColumnWidth
      ) {
        this.displayIconLeft = 1;
      }
    },
    left(el) {
      let id;
      if (el.target.tagName == "svg") {
        id = el.target.parentElement.id;
      } else if (el.target.tagName == "path") {
        id = el.target.parentElement.parentElement.id;
      }
      let items = document
        .querySelector(`#${id}`)
        .parentElement.parentElement.querySelectorAll(".carousel article");
      let carousel;
      if (id == "carousel_left2") {
        carousel = this.carousel[1];
      }
      if (id == "carousel_left1") {
        carousel = this.carousel[0];
      }
      let percentColumnWidth =
        getComputedStyle(carousel).gridAutoColumns.match(/\d+/g)[0];
      let itemsInRow = 100 / percentColumnWidth; //5
      let lastItemDisplay;
      let styleTransform;
      let rightItems;
      let styleGridAutoColumn = getComputedStyle(
        document.querySelector(".flex-gap")
      ).gridAutoColumns.match(/\d+/g); // [20;16]
      if (!carousel.style.transform || carousel.style.transform == "unset") {
        lastItemDisplay = 100 / percentColumnWidth; //5
        rightItems = items.length - lastItemDisplay; // 34
        if (rightItems <= itemsInRow) {
          carousel.style.transform = `translateX(calc(-${
            rightItems * percentColumnWidth
          }% - ${
            (rightItems * percentColumnWidth * styleGridAutoColumn[1]) /
            (itemsInRow - 1) /
            styleGridAutoColumn[0]
          }px`;
        } else if (rightItems > itemsInRow) {
          carousel.style.transform = `translateX(calc(-100% - ${
            (styleGridAutoColumn[1] * itemsInRow) / (itemsInRow - 1)
          }px`;
        }
      } else {
        styleTransform = carousel.style.transform;
        rightItems =
          items.length -
          (styleTransform.match(/\d+/g)[0] * 1) / percentColumnWidth -
          itemsInRow;
        if (rightItems < itemsInRow) {
          carousel.style.transform = `translateX(calc(-${
            rightItems * percentColumnWidth +
            styleTransform.match(/\d+/g)[0] * 1
          }% - ${
            styleTransform.match(/\d+/g)[1] * 1 +
            (styleGridAutoColumn[1] / (itemsInRow - 1)) * rightItems
          }px`;
          rightItems = 0;
        } else if (rightItems >= itemsInRow) {
          carousel.style.transform = `translateX(calc(-${
            styleTransform.match(/\d+/g)[0] * 1 + 100
          }% - ${
            styleTransform.match(/\d+/g)[1] * 1 +
            (styleGridAutoColumn[1] * itemsInRow) / (itemsInRow - 1)
          }px`;
          if (rightItems == itemsInRow) {
            rightItems = 0;
          }
        }
      }
      if (rightItems == 0) {
        this.displayIconLeft = rightItems;
      }
      if (styleGridAutoColumn[0] == 0 || !carousel.style.transform) {
        this.displayIconRight = 0;
      } else {
        this.displayIconRight = 1;
      }
    },
    setting() {
      const iframes = document.querySelectorAll(".img iframe");
      for (let i = 0; i < iframes.length; i++) {
        iframes[i].classList.remove("fullscreen");
      }
      document.documentElement.style.overflow = "auto";
      let carousel = document.querySelectorAll(".carousel");
      for (let m = 0; m < carousel.length; m++) {
        carousel[m].classList.remove("unset");
      }
      document.querySelector(".banner").classList.remove("overlayyy");
      document
        .querySelector(".banner")
        .querySelector(".logo_mobile")
        .classList.remove("aaa");
      document
        .querySelector(".banner")
        .querySelector(".banner_video")
        .classList.remove("aaa");
      document.querySelector(".home").classList.remove("black");
      document.querySelector(".line").classList.remove("collapse");
      document.querySelector("footer").classList.remove("black");
      document
        .querySelector("footer")
        .querySelector(".container")
        .classList.remove("collapse");
      document.querySelector(".more_about").classList.remove("black");
      document
        .querySelector(".more_about")
        .querySelector(".more_about_laptop")
        .classList.remove("collapse");
      document.querySelector("#app").classList.remove("black");
      let lines = document.querySelectorAll(".line");
      for (let k = 0; k < lines.length; k++) {
        lines[k].classList.remove("collapse");
      }
      for (let j = 0; j < iframes.length; j++) {
        iframes[j].classList.remove("collapse");
      }
    },
  },
  mounted() {
    this.carousel = document.querySelectorAll(".carousel");
    this.fullscreen();
    this.setting();
  },
};
</script>
