<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <NavBar></NavBar> -->

    <router-view />
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
// @ is an alias to /src
// import Footer from "@/components/Footer.vue";
export default {
  components: {},
};
</script>

<style lang="scss">
@font-face {
  font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font",
    "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
  src: url("./assets/font/static/Orbitron-Bold.ttf");
}

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/font/Segoe UI.woff");
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/font/Helvetica.ttf");
}
::selection {
  background: #272e34;
  color: #dfdee3;
}
#app {
  font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font",
    "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  max-width: 100%;
  overflow-x: hidden;
}
.black {
  background: black;
  color: black !important;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
