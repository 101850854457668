<template>
  <footer>
    <div class="container">
      <section>
        <div class="location">
          <div class="location_name">LA sales</div>
          <div class="location_detail" style="display: grid">
            <span style="margin-bottom: 5px"> 2021 Peyton Avenue Apt</span>
            <span>118 Burbank, CA 91504, USA</span>
          </div>
          <div class="location_phone">
            <div class="tel">
              <a href="tel:+ 1 702-482-6367">Tel:+ 1 702-482-6367</a>
            </div>
            <div class="email">
              <a href="mailto:info@skyfall.studio"
                >E-mail:info@skyfall.studio</a
              >
            </div>
          </div>
        </div>
        <div class="location">
          <div class="location_name">SEOUL</div>
          <div class="location_detail">
            <span style="margin-bottom: 5px">1422-1423, 475 Hyangdong-dong</span>
            <span>Deogyanggu, Goyangsi, Gyeonggido, Korea</span>
          </div>
          <div class="location_phone">
            <div class="tel">
              <a href="tel:070-8065-4972">Tel: 070-8065-4972</a>
            </div>
            <a href="mailto:info@skyfall.studio">E-mail:info@skyfall.studio</a>
          </div>
        </div>
        <div class="location">
          <div class="location_name">SAIGON</div>
          <div class="location_detail" style="display: grid">
            <span style="margin-bottom: 5px"> 6F HOSE, QTSC</span>
            <span> District 12, HCMC, Vietnam</span>
          </div>
          <div class="location_phone">
            <div class="tel">
              <div class="tel">
                <a href="tel:028-3891-8879">Tel:028-3891-8879</a>
              </div>
            </div>
            <a href="mailto:info@skyfall.studio">E-mail:info@skyfall.studio</a>
          </div>
        </div>
      </section>
      <div class="btn_controls">
        <div class="left" id="carousel_left1" @click="left">
          <svg viewBox="0 0 13 29" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#dfdee3"
              d="M12.716 28.349l-.779.651L0 14.5 11.937 0l.779.651L1.303 14.5z"
            />
          </svg>
        </div>
        <div class="right" @click="right" id="carousel_right1">
          <svg viewBox="0 0 13 29" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#dfdee3"
              d="M12.716 28.349l-.779.651L0 14.5 11.937 0l.779.651L1.303 14.5z"
            />
          </svg>
        </div>
      </div>
      <div class="line"></div>
      <div class="copy_right">
        <div class="left">© SKYFALL 2015. All Rights Reserved.</div>
        <div class="right">
          <div class="icon_fb">
            <div class="round">f</div>
          </div>
          <div class="icon_in">
            <div class="round">
              <div class="square">in</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped lang="scss">
svg {
  cursor: pointer;
}
footer {
  .btn_controls {
    display: none;
  }
  section {
    transition: all 200ms ease-out;
  }
  a {
    color: #fffff0;
  }
  ::selection {
    color: #272e34;
    background: #dfdee3;
  }
  @font-face {
    font-family: "Orbitron";
    src: url("../assets/font/static/Orbitron-Bold.ttf");
  }

  footer {
    background: #272e34;
    font-family: "Orbitron", sans-serif;
  }

  .container {
    text-align: center;
    margin: 0 auto;
    max-width: 1320px;
    color: #fffff0;
    position: relative;
  }

  section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* padding: 10px; */
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .location {
    font-family: "Roboto-Black.ttf", sans-serif;
  }

  .location_name {
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
  }

  .location_detail {
    font-size: 14px;
    padding-bottom: 10px;
    display: grid;
  }

  .location_phone {
    font-size: 14px;
    // display: inline-flex;
    column-gap: 3px;

    .tel {
      margin-bottom: 5px;
    }
    .email {
      a {
        color: #fffff0;
      }
    }
  }

  .line {
    background: white;
    height: 0.5px;
  }

  .copy_right {
    text-align: unset;
    padding: 20px;
    justify-content: flex-end;
    display: -webkit-flex;
    display: flex;

    .left {
      font-size: 14px;
      color: #dfdee3;
      font-family: "Roboto-Black.ttf", sans-serif;
      margin-right: auto;
    }

    .right {
      display: flex;
      gap: 10px;
    }
  }

  .round {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid white;
    font-size: 14px;
    display: grid;
    justify-items: center;
    align-items: center;
    color: white;
  }

  .square {
    display: grid;
    width: 12px;
    height: 12px;
    background: white;
    color: black;
    align-items: center;
    justify-items: center;
    font-size: 10px;
    font-weight: 700;
    font-family: "Roboto-Black.ttf", sans-serif;
  }
  @media only screen and (min-width: 1400px) {
    .container {
      max-width: 1320px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .container {
      max-width: 1140px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .container {
      max-width: 960px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      max-width: 740px;
      section {
        display: grid;
        grid-template-columns: 1fr 1.2fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .btn_controls {
      height: 100%;
      z-index: 2;
      display: block;

      .left {
        transform: scaleX(-1);
        height: 100%;
        margin: 10px;
        position: absolute;
        top: -10%;
        right: 0;
        width: 12px;
        svg {
          position: absolute;
          top: 45%;
        }
      }
      .right {
        margin: 10px;
        height: 100%;
        position: absolute;
        top: -10%;
        left: 0;
        width: 12px;

        svg {
          position: absolute;
          top: 45%;
        }
      }
    }
    .container {
      margin: 0 auto;
      padding: 10px;
    }
    section {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 100%;
      grid-template-columns: none;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 410px) and (max-width: 600px) {
  }
  @media only screen and (min-width: 360px) and (max-width: 409px) {
  }
  @media only screen and (max-width: 359px) {
  }
}

.btn_controls {
  display: none;
}
section {
  transition: all 200ms ease-out;
}
a {
  color: #fffff0;
}
::selection {
  color: #272e34;
  background: #dfdee3;
}
@font-face {
  font-family: "Orbitron";
  src: url("../assets/font/static/Orbitron-Bold.ttf");
}

footer {
  background: #272e34;
  font-family: "Orbitron", sans-serif;
}

.container {
  text-align: center;
  margin: 0 auto;
  max-width: 1320px;
  color: #fffff0;
  position: relative;
}

section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 10px; */
  padding-top: 50px;
  padding-bottom: 50px;
}

.location {
  font-family: "Roboto-Black.ttf", sans-serif;
}

.location_name {
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}

.location_detail {
  font-size: 14px;
  padding-bottom: 10px;
  display: grid;
}

.location_phone {
  font-size: 14px;
  // display: inline-flex;
  column-gap: 3px;

  .tel {
    margin-bottom: 5px;
  }
  .email {
    a {
      color: #fffff0;
    }
  }
}

.line {
  background: white;
  height: 0.5px;
}

.copy_right {
  text-align: unset;
  padding: 20px;
  justify-content: flex-end;
  display: -webkit-flex;
  display: flex;

  .left {
    font-size: 14px;
    color: #dfdee3;
    font-family: "Roboto-Black.ttf", sans-serif;
    margin-right: auto;
  }

  .right {
    display: flex;
    gap: 10px;
  }
}

.round {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  font-size: 14px;
  display: grid;
  justify-items: center;
  align-items: center;
  color: white;
}

.square {
  display: grid;
  width: 12px;
  height: 12px;
  background: white;
  color: black;
  align-items: center;
  justify-items: center;
  font-size: 10px;
  font-weight: 700;
  font-family: "Roboto-Black.ttf", sans-serif;
}
@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 740px;
    section {
      display: grid;
      grid-template-columns: 1fr 1.2fr 1fr;
    }
  }
}
@media only screen and (max-width: 767px) {
  .btn_controls {
    height: 100%;
    z-index: 2;
    display: block;

    .left {
      transform: scaleX(-1);
      height: 100%;
      margin: 10px;
      position: absolute;
      top: -10%;
      right: 0;
      width: 14px;
      svg {
        position: absolute;
        top: 45%;
      }
    }
    .right {
      margin: 10px;
      height: 100%;
      position: absolute;
      top: -10%;
      left: 0;
      width: 14px;

      svg {
        position: absolute;
        top: 45%;
      }
    }
  }
  .container {
    margin: 0 auto;
    padding: 10px;
  }
  section {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    grid-template-columns: none;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
}
@media only screen and (min-width: 360px) and (max-width: 409px) {
}
@media only screen and (max-width: 359px) {
}
.black {
  background: black;
  color: black;
}
.collapse {
  visibility: collapse;
}
</style>

<script>
// import { data1, data2 } from "@/assets/js/data_list.js";

export default {
  name: "Footer",
  data() {
    return {};
  },

  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler(e) {
      let mq1 = window.matchMedia("(min-width: 768px)");
      if (mq1.matches) {
        // console.log("qqq");
        let section = document.querySelector("footer section");
        // console.log(section.style.transform);
        section.style.transform = `translateX(0%)`;
      }
      let mq2 = window.matchMedia("(max-width: 767px)");
      if (mq2.matches) {
        let section = document.querySelector("footer section");
        if (!section.style.transform) {
          section.style.transform = `translateX(-100%)`;
        } else {
          section.style.transform = `translateX(-100%)`;
        }
      }
    },
    transform() {
      let mq = window.matchMedia("(max-width: 767px)");
      if (mq.matches) {
        let section = document.querySelector("footer section");
        if (!section.style.transform) {
          section.style.transform = `translateX(-100%)`;
        }
      }
    },
    left() {
      let mq = window.matchMedia("(max-width: 767px)");
      if (mq.matches) {
        let section = document.querySelector("footer section");
        let percent;
        if (!section.style.transform) {
          section.style.transform = `translateX(-100%)`;
        } else if (section.style.transform.match(/\d+/g) == 100) {
          section.style.transform = `translateX(-200%)`;
        } else if (section.style.transform.match(/\d+/g) == 200) {
          section.style.transform = `translateX(-0%)`;
        } else if (section.style.transform.match(/\d+/g) == 0) {
          section.style.transform = `translateX(-100%)`;
        }
      }
    },
    right() {
      let mq = window.matchMedia("(max-width: 767px)");
      if (mq.matches) {
        let section = document.querySelector("footer section");
        let percent;
        if (!section.style.transform) {
          section.style.transform = `translateX(100%)`;
        } else if (section.style.transform.match(/\d+/g) == 100) {
          section.style.transform = `translateX(0%)`;
        } else if (section.style.transform.match(/\d+/g) == 200) {
          section.style.transform = `translateX(-100%)`;
        } else if (section.style.transform.match(/\d+/g) == 0) {
          section.style.transform = `translateX(-200%)`;
        }
      }
    },
  },
  mounted() {
    this.transform();
  },
};
</script>
