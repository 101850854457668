<template>
  <div class="hamberger">
    <input
      type="checkbox"
      id="btn"
      hidden
      :checked="isChecked"
      v-model="isChecked"
    />
    <label for="btn" class="menu-btn">
      <i class="fas fa-bars"></i>
      <i class="fas fa-times"></i>
    </label>
    <nav id="sidebar">
      <div class="title">
        <router-link to="/"
          ><img src="@/assets/img/Logo_64pixel.png" alt="" style="opacity: 10%"
        /></router-link>
      </div>
      <ul class="list-items">
        <li>
          <router-link to="/"><i class="fas fa-home"></i>Home</router-link>
        </li>

        <li>
          <router-link to="/gallery"><i class="fas fa-stream"></i>Gallery</router-link>
        </li>
        <li>
          <router-link to="/career"><i class="fas fa-film"></i>Career</router-link>
        </li>
        <li>
          <router-link to="/about"><i class="fas fa-user"></i>About us</router-link>
        </li>

        <div class="icons">
          <a href="https://www.facebook.com/SKYFALLvina"><i class="fab fa-facebook-f"></i></a>
          <a href="https://www.linkedin.com/company/skyfallstudio"><i class="fab fa-linkedin"></i></a>
        </div>
      </ul>
    </nav>
    <div class="overlay" @click="hideMenu()"></div>
  </div>
</template>
<style scoped lang="scss">
.hamberger {
  .overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 14; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  .menu-btn {
    position: absolute;
    right: 20px;
    top: 10px;
    background: #4a4a4a;
    color: #fff;
    height: 45px;
    width: 45px;
    z-index: 9999;
    border: 1px solid #333;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  #btn:checked ~ .menu-btn {
    right: 247px;
  }
  .menu-btn svg {
    position: absolute;
    /* transform: ; */
    font-size: 23px;
    transition: all 0.3s ease;
  }
  .menu-btn .fa-xmark {
    opacity: 0;
  }
  #btn:checked ~ .menu-btn .fa-xmark {
    opacity: 1;
    transform: rotate(-180deg);
  }
  #btn:checked ~ .menu-btn .fa-bars {
    opacity: 0;
    transform: rotate(180deg);
  }
  #btn:checked ~ .overlay {
    display: block;
  }
  #sidebar {
    position: fixed;
    background: #404040;
    height: 100%;
    width: 270px;
    overflow: hidden;
    right: -100%;
    transition: all 0.3s ease;
    z-index: 1000;
  }
  #btn:checked ~ #sidebar {
    right: 0;
  }
  #sidebar .title {
    line-height: 65px;
    text-align: center;
    /* background: #333; */
    font-size: 25px;
    font-weight: 600;
    color: #f2f2f2;
    /* border-bottom: 1px solid #222; */
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 10px;
  }
  #sidebar .list-items {
    position: relative;
    background: #404040;
    width: 100%;
    height: 100%;
    list-style: none;
  }
  #sidebar .list-items li {
    padding-left: 40px;
    line-height: 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid #333;
    transition: all 0.3s ease;
  }
  #sidebar .list-items li:hover {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-shadow: 0 0px 10px 3px #222;
  }
  #sidebar .list-items li:first-child {
    border-top: none;
  }
  #sidebar .list-items li a {
    color: #f2f2f2;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    height: 100%;
    width: 100%;
    display: block;
  }
  #sidebar .list-items li a svg {
    margin-right: 20px;
  }
  #sidebar .list-items .icons {
    width: 100%;
    height: 40px;
    text-align: center;
    position: absolute;
    bottom: 100px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #sidebar .list-items .icons a {
    height: 100%;
    width: 40px;
    display: block;
    margin: 0 5px;
    font-size: 18px;
    color: #f2f2f2;
    background: #4a4a4a;
    border-radius: 5px;
    border: 1px solid #383838;
    transition: all 0.3s ease;
  }
  #sidebar .list-items .icons a:hover {
    background: #404040;
  }
  .list-items .icons a:first-child {
    margin-left: 0px;
  }
}
</style>
<script>
export default {
  name: "Hamberger",
  components: {},
  data() {
    return {
      isChecked: false,
    };
  },
  created() {},
  computed: {},
  destroyed() {},
  methods: {
    hideMenu() {
      let input = document.querySelector("input");
      input.checked = false;
      this.isChecked = false;
    },
    abc() {
      document.documentElement.style.overflow = "auto";
    },
  },
  mounted() {
    this.abc();
  },
  watch: {
    isChecked() {
      if (this.isChecked) {
        document.documentElement.style.overflow = "hidden";
        return;
      }

      document.documentElement.style.overflow = "auto";
    },
  },
};
</script>
