<template>
  <div class="banner">
    <div class="logo_mobile">
      <div class="logo_img">
        <router-link to="/"
          ><img src="@/assets/img/Logo_64pixel.png" alt="logo"
        /></router-link>
      </div>
    </div>
    <Hamberger></Hamberger>
    <div class="overlay" @click="offMenu"></div>

    <div class="banner_video banner_laptop">
      <img
        :src="require(`@/assets/img/${this.bannerImg}`)"
        alt=""
        style="width: 100%"
      />
      <div class="container">
        <section class="banner">
          <div class="logo">
            <div class="logo_img">
              <router-link to="/"
                ><img src="@/assets/img/Logo_64pixel.png" alt="logo"
              /></router-link>
            </div>
            <div class="button" v-if="['Us', 'Career'].includes(this.name)">
              <!-- v-if="['HomeView', 'Gallery'].includes(this.name)"  -->

              <a href="mailto:info@skyfall.studio" style="color: unset"
                ><div class="button_reach" style="height: 100%">Reach</div>
              </a>
            </div>
          </div>
          <div class="line"></div>
          <div class="info">
            <span
              class="info1"
              style="padding: 10px; display: grid; align-items: center"
            >
              (주)스카이폴은 상상력을 제공하는 시각효과 스튜디오 입니다.
            </span>
            <span
              class="info2"
              style="padding: 10px; display: grid; align-items: center"
            >
              <div>
                We harness the power of art and technology to create VFX magic
                for you.
              </div>
            </span>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.overlayyy {
  background: black;
}
.logo_mobile.aaa {
  visibility: collapse;
}
.banner_video.aaa {
  visibility: collapse;
}

.gallery {
  .logo_img {
    img {
      // width: 8%;
      opacity: 20%;
    }
  }
  .overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .list {
    display: none;
  }

  .menu {
    display: none;
    position: absolute;
  }

  ::selection {
    color: #272e34;
    background: #dfdee3;
  }
  .banner_btn {
    bottom: 6%;
    position: absolute;
    left: 1%;
  }
  .banner_video {
    position: relative;
    padding-bottom: 5vh;
    // height: 100vh;
    overflow: hidden;
  }

  .banner {
    // width: 100%;
    // height: 90vh;
    // background-position: center; /* Center the image */
    // background-repeat: no-repeat; /* Do not repeat the image */
    // background-size: cover; /* Resize the background image to cover the entire container */
    position: relative;
    .container {
      max-width: 1320px;
      margin: 0 auto;
      box-sizing: border-box;
      position: absolute;
      bottom: 40px;
      right: 0;
      left: 0;
      z-index: 3;

      .logo {
        padding-top: 20%;
        display: grid;
        grid-template-columns: 7fr 3fr;
        &_img {
          margin-left: 20px;
          img {
            // width: 10%;
          }
        }
        .button {
          width: 100%;
          box-sizing: border-box;
          background: #dfdee3;
          border-radius: 3px;
          display: grid;

          &_reach {
            display: grid;
            align-content: center;
            justify-content: center;
            font-weight: 600;
          }
        }
      }

      .line {
        height: 2px;
        background: #dfdee3;
        width: 100%;
        margin: 10px 0;
      }
      .info {
        display: grid;
        grid-template-columns: 7fr 3fr;
        color: #dfdee3;
        padding: 10px 0;
        // font-weight: 600;
        // font-family: "Apple Korea";

        .info1 {
          // width: 55%;
        }
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    .banner .banner_video .container {
      max-width: 1320px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner .banner_video .container {
      max-width: 1140px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner .banner_video .container {
      max-width: 960px;
    }
    .banner_btn {
      bottom: 8%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner .banner_video .container {
      max-width: 720px;
    }
    .banner_btn {
      bottom: 11%;
    }
    .banner_video .container .info {
      // font-size: 14px;
    }
  }

  @media only screen and (min-width: 768px) {
    .logo_mobile {
      display: none;
    }
    .banner .container .info {
      font-size: 28px !important;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 410px) and (max-width: 600px) {
  }
  @media only screen and (min-width: 360px) and (max-width: 409px) {
  }
  @media only screen and (max-width: 359px) {
  }
}
.logo_img {
  img {
    // width: 8%;
    opacity: 20%;
  }
}
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.list {
  display: none;
}

.menu {
  display: none;
  position: absolute;
}

::selection {
  color: #272e34;
  background: #dfdee3;
}
.banner_btn {
  bottom: 6%;
  position: absolute;
  left: 1%;
}
.banner_video {
  position: relative;
  margin-bottom: 5vh;
  // height: 100vh;
  overflow: hidden;
}

.track {
  position: relative;
}

.banner {
  // width: 100%;
  // height: 90vh;
  // background-position: center; /* Center the image */
  // background-repeat: no-repeat; /* Do not repeat the image */
  // background-size: cover; /* Resize the background image to cover the entire container */
  position: relative;
  .container {
    max-width: 1320px;
    margin: 0 auto;
    box-sizing: border-box;
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    z-index: 3;

    .logo {
      padding-top: 20%;
      display: grid;
      grid-template-columns: 7fr 3fr;
      &_img {
        margin-left: 20px;
        img {
          // width: 10%;
        }
      }
      .button {
        width: 100%;
        box-sizing: border-box;
        background: #dfdee3;
        border-radius: 3px;
        display: grid;

        &_reach {
          display: grid;
          align-content: center;
          justify-content: center;
          font-weight: 600;
        }
      }
    }

    .line {
      height: 2px;
      background: #dfdee3;
      width: 100%;
      margin: 10px 0;
    }
    .info {
      display: grid;
      grid-template-columns: 7fr 3fr;
      color: #dfdee3;
      font-size: 18px; // font-weight: 600;
      .info1 {
        // width: 55%;
      }
      .info2 {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .banner .banner_video .container {
    max-width: 1320px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner .banner_video .container {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner .banner_video .container {
    max-width: 960px;
  }
  .banner_btn {
    bottom: 8%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner .banner_video .container {
    max-width: 720px;
  }
  .banner_btn {
    bottom: 11%;
  }
  .banner_video .container .info {
    // font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .logo_mobile {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  // .banner_video .container{
  //   display: none;
  // }
  .logo_mobile a img {
    width: 50%;
  }

  .banner_laptop canvas,
  .banner_laptop .banner_btn {
    display: none !important;
  }
  .active {
    background: #e1dfd4;
    border-radius: 6px;
  }

  .list {
    display: block;
    width: 30%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background: #edebf0;
    transform: translateX(100%);
    transition: all 0.1s ease-in-out;
    .close {
      position: absolute;
      left: 13px;
      top: 13px;
      width: 2px;
      height: 13px;
      opacity: 1;
    }

    .close:before,
    .close:after {
      position: absolute;
      left: 13px;
      content: " ";
      height: 13px;
      width: 2px;
      background-color: #333;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }

    ul {
      padding: 20px;
      margin-top: 20px;
      li {
        border-bottom: #e2e0e5 solid 1px;
        text-align: center;
        a {
          color: #717075;
          align-items: center;
          display: grid;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-weight: 700;
          // font-size: 12px;
          padding: 10px 0;
        }
      }
    }
  }
  .menu {
    display: block;
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 2;
    &_bar {
      width: 15px;
      height: 2px;
      background: #dfdee3;
      margin: 3px;
      border-radius: 5px;
    }
  }

  .banner {
    .container {
      padding: 10px;
      bottom: 5px;
      .info {
        grid-template-columns: unset;
        gap: 0px;
        font-size: 14px;
        .info1 {
          display: grid;
          width: 100%;
          text-align: start !important;
          font-size: 14px !important;
          padding: 5px !important;
        }
        .info2 {
          display: grid;
          align-items: start !important;
          // margin: 0 auto;
          font-size: 12px !important;
          padding: 5px !important;
        }
      }
      .line {
        height: 1px;
        margin: 10px 0;
      }
    }
  }

  .banner_btn {
    bottom: 40px;
    left: 10px;
  }
  .banner_video .container {
    // font-size: 8px;
    .logo {
      display: none;
    }
  }
  .logo_mobile {
    z-index: 10;
    position: absolute;
    top: 1%;
    left: 1%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
}
@media only screen and (min-width: 410px) and (max-width: 600px) {
}
@media only screen and (min-width: 360px) and (max-width: 409px) {
}
@media only screen and (max-width: 359px) {
}
</style>
<script>
import Hamberger from "@/components/Hamberger.vue";
export default {
  name: "Banner",
  // bannerImg:bannerImg,
  components: {
    Hamberger,
  },
  props: {
    greetingMessage: Array,
    greetingMessage1: Array,
    name: String,
    bannerImg:String,

  },
  data() {
    return {
      meida: "",
      abc: "",
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
   
    trackLocation(e) {
      var videoContainer = document.getElementById("video-compare-container1");
      var videoClipper = document.getElementById("video-clipper1");
      var clippedVideo = videoClipper.getElementsByTagName("IMG")[0];
      var rect = videoContainer.getBoundingClientRect(),
        position = ((e.pageX - rect.left) / videoContainer.offsetWidth) * 100;
      if (position <= 100) {
        videoClipper.style.width = position + "%";
        clippedVideo.style.width = (100 / position) * 100 + "%";
        clippedVideo.style.zIndex = 3;
      }
    },
    myEventHandler() {
      let mq1 = window.matchMedia("(max-width: 767px)");
      if (mq1.matches) {
        // document.querySelector("#rightVideo").autoplay = false;
        // document.querySelector("#leftVideo").autoplay = false;
        // document.querySelector("#rightVideo").pause();
        // document.querySelector("#leftVideo").pause();
      } else {
      }
    },

    displayMenu() {
      let menu = document.querySelector(".list");
      menu.style.transform = "translateX(0%)";
      let overlay = document.querySelector(".overlay");
      overlay.style.display = "block";
    },
    offMenu() {
      let menu = document.querySelector(".list");
      menu.style.transform = "translateX(100%)";
      let overlay = document.querySelector(".overlay");
      overlay.style.display = "none";
    },
    setting() {
      let videoControl = document.querySelector("button");
      videoControl.innerHTML = "Play";
      var videoContainer = document.getElementById("video-compare-container");
      videoContainer.style.display = "none";
    },
    playPause() {
      let mq = window.matchMedia("(min-width: 768px)");
      if (mq.matches) {
        let leftVideo = document.getElementById("leftVideo");
        let rightVideo = document.getElementById("rightVideo");
        let videoControl = document.querySelector("button");

        if (leftVideo.paused) {
          videoControl.innerHTML = "Pause";
          this.playVids();
        } else {
          leftVideo.pause();
          rightVideo.pause();
          videoControl.innerHTML = "Play";
        }
      }
    },
    playVids() {
      let position = 0.5;
      let vidHeight = 1024;
      let vidWidth = 2048;
      let videoMerge = document.getElementById("videoMerge");
      let mergeContext = videoMerge.getContext("2d");
      let leftVideo = document.getElementById("leftVideo");
      let rightVideo = document.getElementById("rightVideo");
      let videoControl = document.querySelector("button");

      // if (leftVideo.readyState > 3 && rightVideo.readyState > 3)
      //  {
      leftVideo.play();
      rightVideo.play();
      function trackLocation(e) {
        position = (e.pageX - videoMerge.offsetLeft) / videoMerge.offsetWidth;

        if (position <= 1 && position >= 0) {
          leftVideo.volume = position;
          rightVideo.volume = 1 - position;
        }
      }

      Number.prototype.clamp = function (min, max) {
        return Math.min(Math.max(this, min), max);
      };

      videoMerge.addEventListener("mousemove", trackLocation, false);
      videoMerge.addEventListener("touchstart", trackLocation, false);
      videoMerge.addEventListener("touchmove", trackLocation, false);

      let banner_video = document.querySelector(".banner_video");

      banner_video.addEventListener("mousemove", trackLocation, false);
      banner_video.addEventListener("touchstart", trackLocation, false);
      banner_video.addEventListener("touchmove", trackLocation, false);

      function drawLoop() {
        mergeContext.drawImage(
          leftVideo,
          0,
          0,
          vidWidth,
          vidHeight,
          0,
          0,
          vidWidth,
          vidHeight
        );
        mergeContext.drawImage(
          rightVideo,
          (vidWidth * position).clamp(0.01, vidWidth),
          0,
          (vidWidth - vidWidth * position).clamp(0.01, vidWidth),
          vidHeight,
          (vidWidth * position).clamp(0.01, vidWidth),
          0,
          (vidWidth - vidWidth * position).clamp(0.01, vidWidth),
          vidHeight
        );
        requestAnimationFrame(drawLoop);
      }

      requestAnimationFrame(drawLoop);
      // }
    },
    setMenu(id) {
      this.activeId = id;
    },
  },
  mounted() {
    // this.setting();
    // this.playPause();
    // this.myEventHandler();
  },
};
</script>
